import { Dropdown } from '../../dropdown/dropdown';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { Loader } from 'components/lib';

/**
 * DropdownQodo - A generic dropdown component
 *
 * @param {Object} props
 * @param {string} [props.triggerText='Select'] - Text for dropdown button
 * @param {MenuItem[]} [props.menuItems=[]] - Available options
 * @param {SelectCallback} props.onSelect - Handle selection
 * @param {boolean} [props.isLoading=false] - Show loading spinner
 *
 * @typedef {Object} MenuItem
 * @property {string} label - Display text
 * @property {string} value - Option value
 * @property {string} [props.subLabel='']: "Subtext" - Display optional sub label
 *
 * @typedef {(value: string) => void} SelectCallback
 *
 * @example
 * <DropdownQodo
 *   triggerText="Choose"
 *   menuItems={[
 *     { label: "Option 1", value: 1 },
 *     { label: "Option 2", value: 2 }
 *   ]}
 *   onSelect={console.log}
 * />
 *   menuItems={[
 *     { label: "Option 1", value: 1 },
 *     { label: "Option 2", value: 2, subLabel: "Subtext" }
 *   ]}
 *   onSelect={console.log}
 * />
 */

export const QodoDropdown = ({
  onSelect,
  triggerText = 'Select',
  menuItems = [{ label: '', value: '', subLabel: '' }],
  isLoading = false,
}) => (
  <div>
    <Dropdown>
      <Dropdown.Trigger>
        {isLoading ? (
          <Loader className="w-4 h-4 animate-spin" />
        ) : (
          <div className="flex items-center gap-1 cursor-pointer text-brand1-300">
            <span className="inline-flex items-center">{triggerText}</span>
            <ChevronDownIcon />
          </div>
        )}
      </Dropdown.Trigger>
      <Dropdown.Menu>
        {menuItems.map(({ label, subLabel, value }, index) => (
          <Dropdown.MenuItem
            key={`${index}-${label}`}
            onSelect={() => onSelect(value)}
          >
            {label}
            {subLabel && <div className="text-gray-500">{subLabel}</div>}
          </Dropdown.MenuItem>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  </div>
);
