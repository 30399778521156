import { gtagService } from 'app/analytics/gtag';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const removeQueryParams = (url: string) => {
  try {
    const urlObject = new URL(url);
    return `${urlObject.protocol}//${urlObject.host}${urlObject.pathname}`;
  } catch (e) {
    console.warn('Invalid URL:', url);
    return url;
  }
};
const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    // Track the page view when location changes
    gtagService.trackEvent('page_view', {
      page_location: removeQueryParams(window.location.href),
      page_title: document.title,
    });
  }, [location]);
};

export const PageViewTracker = () => {
  usePageTracking();
  return null;
};
