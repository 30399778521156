import { QodoDropdown } from '../../../components/qodo/qodoDropdown';
import { getPermissionByRole } from '../helpers';
import { UserRoles } from '../types';
import { useLoadingContext } from './permissionLoadingContext';

export const OrgPermission = ({
  user,
  updateUser,
}: {
  user: any;
  updateUser: any;
}) => {
  const { isLoading, setLoading } = useLoadingContext();

  const permission = getPermissionByRole(user.organization_permission);

  // Once qodoDropdown is .ts, remove the `| string` from the argument type
  const handlePermissionChange = async (newPermission: UserRoles | string) => {
    const newDisplayPermission = getPermissionByRole(newPermission);

    if (permission === newDisplayPermission || isLoading(user.id)) {
      return;
    }

    setLoading(user.id, true);

    try {
      await updateUser({
        attributes: { organization_permission: newPermission },
        user_id: user.id,
      });
    } catch (error) {
      console.error(`Failed to update user ${user.id}:`, error);
    } finally {
      setLoading(user.id, false);
    }
  };

  return (
    <QodoDropdown
      onSelect={handlePermissionChange}
      triggerText={permission}
      menuItems={[
        { label: 'Member', value: 'user' },
        { label: 'Admin', value: 'owner' },
      ]}
      isLoading={isLoading(user.id)}
    />
  );
};
