export const gtagService = {
  trackEvent: (event, eventProperties = {}) => {
    if (!window.dataLayer) {
      console.warn('missing Google Tag Manager dataLayer');
      return;
    }
    window.dataLayer.push({
      event,
      ...eventProperties,
    });
  },
};
