import { mixpanelService } from 'app/analytics';
import Axios from 'axios';

export const baseRoute = 'platform/v2';

export const ENDPOINTS = {
  USERS: 'users',
  DISCONNECT_GIT_PROVIDER: 'users/disconnect-git-provider',
  TEAM_USERS: 'teams/users',
  TEAMS: 'teams',
  TEAMS_PLANS: 'teams/plans',
  TEAMS_CREATE_CUSTOMER_PORTAL_SESSION: 'teams/create-customer-portal-session',
  CREATE_CHECKOUT_SESSION: 'teams/create-checkout-session',
  INVITES: 'invites',
  ORGANIZATIONS: 'organizations',
  ORG_MEMBERS: 'organizations/members',
  ORG_TEAMS: 'organizations/teams',
  SSO_SETUP: 'organizations/sso-setup',
  ORG_CREATE_CUSTOMER_PORTAL_SESSION:
    'organizations/create-customer-portal-session',
  REQUEST_ACCESS: 'organizations/requests',
  ENV_USERS: 'organizations/members-by-envs',
  INVITE_USER_TO_ORG: 'invites/organization',
};

export const apiService = {
  async callApi({
    endpoint,
    method = 'get',
    data = null,
    errorEvent = '',
    params = null,
  }) {
    try {
      const res = await Axios({
        url: `${baseRoute}/${endpoint}`,
        params,
        method,
        ...(data && { data }),
      });
      return res?.data?.data;
    } catch (err) {
      mixpanelService.trackError(errorEvent, {
        endpoint,
        error_message: err?.message || err?.response?.data?.detail,
        request_id: err?.config?.headers?.['Request-id'] || 'unknown',
      });
      throw err;
    }
  },
};
