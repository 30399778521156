import React from 'react';
import { Button, Search, TitleRow } from 'components/lib';
import { OrgTitle } from './orgTitle';
import { ManageOrgDropdown } from '../manageOrgDropdown';

export const OrganizationHeader = ({
  organization,
  handleCreateStripeSession,
  addTeam,
  data,
  search,
  inviteMembersToOrg,
}) => (
  <TitleRow className={'my-5'} title={<OrgTitle organization={organization} />}>
    <div className="flex gap-2">
      <Search
        className={'flex mb-2 md:mb-0 flex-1 h-9'}
        inputClassName={
          'dark:bg-zinc-800 outline-zinc-800 outline-1 border-2 border-zinc-700 hover:border-zinc-500 focus-visible:border-zinc-400 focus-visible:outline-none'
        }
        callback={search}
      />
      <ManageOrgDropdown
        createTeam={() =>
          addTeam({
            adminOptions: data?.pages?.[0]?.users?.map((member) => ({
              label: member.name,
              value: member.id,
            })),
          })
        }
      />
      <Button
        small
        text="See invoices"
        action={() => handleCreateStripeSession()}
      />
      <Button small text="Invite Members" action={inviteMembersToOrg} />
    </div>
  </TitleRow>
);
