import Axios from 'axios';
import { v4 as uuid4 } from 'uuid';

// components
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Loader, View } from 'components/lib';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Users } from 'views/account/users';
import { Home } from 'views/home/home';
import { AuthContext, PrivateRoute } from './auth';
// 404
import { NotFound } from 'views/error/404';

import { getAuth } from 'firebase/auth';
import { useContext, useEffect, useMemo, useState } from 'react';
import '../css/output.css';
import { Plans } from '../views/plans/plans';
import { PRAgent } from '../views/qodoMerge/qodoMerge';
import { Dashboard } from '../views/dashboard/dashboard';
import { Organization } from '../views/organization/organization';
import { mixpanelService } from './analytics/mixpanel';
import authRoutes from 'routes/auth';
import { useUser } from 'components/hooks/useUser';
import { PageViewTracker } from 'components/hooks/usePageTracking';

// settings
const Settings = require('settings.json');
const env = process.env.REACT_APP_BUILD_ENV || process.env.NODE_ENV;
const StripePromise = loadStripe(Settings[env].stripe.publishableAPIKey);

export default function App() {
  const auth = getAuth();

  const { isInitialized: isAuthInitialized } = useContext(AuthContext);
  const [isInitialized, setIsInitialized] = useState(false);
  const { user, isOrganizationOwner } = useUser({
    enabled: isInitialized === true,
  });

  useEffect(() => {
    mixpanelService.init();
  }, []);

  useEffect(() => {
    if (!isAuthInitialized) return;
    Axios.defaults.baseURL = Settings[env].server_url;
    Axios.interceptors.request.use(
      async (config) => {
        config.headers['Request-id'] = uuid4();
        config.headers['Access-Control-Allow-Origin'] = '*';
        // get token from firebase
        const user = auth.currentUser;
        if (user) {
          const token = await user.getIdToken();
          config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );

    document.getElementById('app').classList.add('dark');

    setIsInitialized(true);
  }, [isAuthInitialized]);

  const routes = useMemo(() => {
    if (!isInitialized) return [];
    const routesList = [
      ...authRoutes,
      {
        path: '/team',
        view: Users,
        layout: 'app',
        title: 'Team',
      },
      {
        path: '/team/:teamId/users',
        view: Users,
        layout: 'app',
        title: 'Team',
      },
      {
        path: '/plans',
        view: Plans,
        layout: 'app',
        title: 'Plans',
      },
      {
        path: '/qodo-merge',
        view: PRAgent,
        layout: 'app',
        title: 'Qodo Merge',
      },
      {
        path: '/',
        view: Home,
        layout: 'app',
        title: 'Home',
      },
    ];

    if (isOrganizationOwner) {
      routesList.push(
        {
          path: '/dashboard',
          view: Dashboard,
          layout: 'app',
          title: 'Dashboard',
        },
        {
          path: '/organization',
          view: Organization,
          layout: 'app',
          title: 'Organization',
        },
      );
    }

    return routesList;
  }, [isInitialized, user]);

  return (
    <Elements stripe={StripePromise}>
      <BrowserRouter>
        <PageViewTracker />
        {isInitialized && user ? (
          <Routes>
            {routes.map((route) => {
              return (
                <Route
                  key={route.path}
                  path={route.path}
                  element={
                    route.permission ? (
                      <PrivateRoute permission={route.permission}>
                        <View
                          display={route.view}
                          layout={route.layout}
                          title={route.title}
                        />
                      </PrivateRoute>
                    ) : (
                      <View
                        display={route.view}
                        layout={route.layout}
                        title={route.title}
                      />
                    )
                  }
                />
              );
            })}
            {/* 404 */}
            <Route
              path="*"
              element={
                <View display={NotFound} layout="auth" title="404 Not Found" />
              }
            />
          </Routes>
        ) : (
          <Loader center />
        )}
      </BrowserRouter>
    </Elements>
  );
}
