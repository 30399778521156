import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { disconnectGitProvider, getUser } from 'api/user';
import { useEffect, useState } from 'react';
import { ORG_PERMISSIONS, TIER_LEVELS } from '../../constants';

const isTrialUserCheck = (user) => {
  const { tier_end_date, tier_level } = user;

  if (tier_end_date && tier_level === 10) {
    const currentDate = new Date();
    const tierEndDate = new Date(tier_end_date);
    const diffTime = tierEndDate.getTime() - currentDate.getTime();
    const diffDays = Math.max(Math.ceil(diffTime / (1000 * 60 * 60 * 24)), 0);
    return { isTrialUser: diffDays > 0, dayLeftForTrial: diffDays };
  }

  return { isTrialUser: false, dayLeftForTrial: 0 };
};

export const useUser = (queryOptions = {}) => {
  const queryClient = useQueryClient();

  const [isTrialUser, setIsTrialUser] = useState(false);
  const [isFreeUser, setIsFreeUser] = useState(false);
  const [dayLeftForTrial, setDayLeftForTrial] = useState(0);
  const { data: user, refetch: refetchUser } = useQuery({
    ...queryOptions,
    queryKey: ['user'],
    queryFn: getUser,
  });

  const disconnectGitProviderMutation = useMutation({
    mutationFn: disconnectGitProvider,
    onSuccess: (data) => {
      queryClient.setQueriesData({ queryKey: ['user'] }, data);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['user'] });
    },
  });

  useEffect(() => {
    if (user) {
      const { isTrialUser, dayLeftForTrial } = isTrialUserCheck(user);
      setIsTrialUser(isTrialUser);
      setDayLeftForTrial(dayLeftForTrial);
      setIsFreeUser(user.tier_level === TIER_LEVELS.FREE);
    }
  }, [user]);

  return {
    user,
    isTrialUser,
    isFreeUser,
    isOrganizationOwner:
      user?.organization_permission === ORG_PERMISSIONS.OWNER,
    isTeamOwner: user?.permission === 'owner',
    dayLeftForTrial,
    disconnectGitProviderMutation,
    refetchUser,
  };
};
