/***
 *
 *   USER
 *   Shows the current user name and avatar
 *   If user belongs to more than one account, they can switch accounts here
 *
 **********/

import { AuthContext, Badge, Dropdown, Icon } from 'components/lib';
import { useContext, useState } from 'react';
import { UpgradeNow } from './upgradeNow.jsx';
import Style from './user.tailwind.js';
import { useUser } from 'components/hooks/useUser.js';
import { TIER_LEVELS, TIER_LEVEL_TO_TITLE } from 'constants.js';
import { UserSettingsDialog } from './userSettingsDialog';
import { QodoActionDropdown } from 'components/qodo/qodoActionDropdown/index.js';
import { QodoButtonLabel } from 'components/qodo/qodoButtonLabel/qodoButtonLabel.jsx';
import profilePlaceholder from 'assets/profile-placeholder.svg';
import { gtagService } from 'app/analytics/gtag.js';

export const User = () => {
  const authContext = useContext(AuthContext);
  const { user, isTrialUser } = useUser();
  const [isSettingsDialogOpen, setSettingsDialogOpen] = useState(false);
  const accountDropdownMenuItems = [
    {
      label: user?.email,
      labelTitle: 'Account',
      disablePointerEvents: true,
    },
    {
      label: 'Plan',
      extras: [
        user?.tier_level && !isTrialUser && (
          <Badge
            className="bg-gradient-to-br from-[#48FED2] to-[#05AFE5] text-gray-950 rounded-[14px]"
            text={TIER_LEVEL_TO_TITLE[user.tier_level]}
          />
        ),
        user?.tier_level >= TIER_LEVELS.PAID && (
          <Badge
            className="bg-gradient-to-br from-[#8D64FF] to-[#BB9BFF] text-gray-950 rounded-[14px]"
            text="Qodo Merge PRO"
          />
        ),
      ],
    },
    {
      label: 'Account Settings',
      onSelect: () => setSettingsDialogOpen(true),
    },
    {
      label: 'Go to docs',
      onSelect: () => window.open('https://docs.qodo.ai/', '_blank'),
    },
    {
      label: 'Sign out',
      onSelect: () => {
        authContext.signout();
      },
    },
  ];

  return (
    <>
      <div className={Style.user}>
        <div className="flex gap-2 items-center">
          <UpgradeNow />
          <div>
            <QodoActionDropdown
              menuItems={accountDropdownMenuItems}
              triggerElement={
                <QodoButtonLabel
                  buttonText="Account"
                  type="secondary"
                  size="s"
                  rightChevron
                  leftCustomIcon={profilePlaceholder}
                />
              }
            />
          </div>
        </div>
      </div>
      {isSettingsDialogOpen && (
        <UserSettingsDialog closeModal={() => setSettingsDialogOpen(false)} />
      )}
    </>
  );
};
