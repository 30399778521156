import { Animate, Card } from 'components/lib';
import { useUser } from 'components/hooks/useUser';
import { PortalNav } from 'components/nav/sub/portal';
import { DEFAULT_TRIAL_DAYS, TIER_LEVELS } from '../../constants';
import { HomeActions } from './homeActions';
import { Invitation } from './invitation';
import { QodoGenButtons } from './qodoGenButtons';
import {
  QodoMergeButtons,
  TicketTrackingSystemsButtons,
} from './qodoMergeButtons';
import { RequestAccess } from './requestAccess';
import { TitledContent } from './titledContent';

const TRIAL_MESSAGE_FOR_NEW_USERS = `Your ${DEFAULT_TRIAL_DAYS}-days free trial of our Teams plan starts today. Enjoy!`;

export const Home = () => {
  const { user, dayLeftForTrial, isOrganizationOwner } = useUser();
  const isUserPaying = user?.tier_level >= TIER_LEVELS.TRIAL;

  return (
    <div className="flex flex-col gap-3 md:gap-6">
      <PortalNav />
      <Animate>
        <div className="flex flex-col gap-3 md:gap-6">
          <div className="flex flex-col justify-between ">
            <span className="dark:text-stone-300 text-base md:text-lg font-semibold">
              {'Welcome to Qodo'}
            </span>
            <span className={'dark:text-stone-400'}>
              {dayLeftForTrial === DEFAULT_TRIAL_DAYS &&
                TRIAL_MESSAGE_FOR_NEW_USERS}
            </span>
          </div>
          <RequestAccess />
          <Invitation />
          <div className="flex flex-row gap-4">
            <Card className="flex flex-col flex-1 items-start space-y-4 md:space-y-6 p-3 md:p-4 rounded-md self-stretch dark:bg-zinc-800">
              <span
                className={'dark:text-stone-300 text-sm md:text-base w-full'}
              >
                {'Install Qodo Gen extension in your IDE'}
              </span>
              <div className="flex flex-col md:flex-row w-full justify-between gap-6">
                <TitledContent title="Supported IDEs">
                  <QodoGenButtons />
                </TitledContent>
              </div>
            </Card>
            {isUserPaying && (
              <Card className="flex flex-col flex-1 items-start space-y-4 md:space-y-6 p-3 md:p-4 rounded-md self-stretch dark:bg-zinc-800">
                <span className="dark:text-stone-300 text-sm md:text-base w-full">
                  {'Connect Qodo Merge to your repositories'}
                </span>
                <div className="flex flex-col md:flex-row w-full justify-between gap-6">
                  <TitledContent title="Supported Git Providers">
                    <QodoMergeButtons />
                  </TitledContent>
                  <TitledContent title="Supported Ticket Tracking Systems">
                    <TicketTrackingSystemsButtons />
                  </TitledContent>
                </div>
              </Card>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-3 md:gap-6">
          <span className="dark:text-stone-300 text-base md:text-lg">
            {'You might also want to'}
          </span>
          <HomeActions />
        </div>
      </Animate>
    </div>
  );
};
