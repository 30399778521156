import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuRadioItem,
  DropdownMenuRadioGroup,
  DropdownMenuLabel,
} from 'components/ui/dropdown-menu';
import React from 'react';

type DropmenuLabel = React.ReactNode | string;

export type MenuItem = {
  label: string;
  labelTitle?: string;
  extras?: React.ReactNode[];
  radioGroup?: {
    value: string;
    onSelect: (value: string) => void;
    radioItems: radioItem[];
  };
  disabled?: boolean;
  onSelect?: () => void;
  disablePointerEvents?: boolean;
};

type radioItem = {
  disabled?: boolean;
  label: string;
  value: string;
  showValue?: boolean;
};

interface QodoActionDropdownProps {
  menuItems: MenuItem[];
  triggerElement: DropmenuLabel;
}

export const QodoActionDropdown = (props: QodoActionDropdownProps) => {
  if (props.menuItems.length === 0) {
    return <button>{props.triggerElement}</button>;
  }
  return (
    <div>
      <DropdownMenu>
        <DropdownMenuTrigger className="select-none outline-none">
          {props.triggerElement}
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          {props.menuItems.map((menuItem, index) => (
            <React.Fragment key={`menu-item-${index}`}>
              {menuItem.radioGroup ? (
                <React.Fragment key={`radio-group-${index}`}>
                  <DropdownMenuLabel>{menuItem.label}</DropdownMenuLabel>
                  <DropdownMenuRadioGroup
                    value={menuItem.radioGroup.value}
                    onValueChange={(value) => {
                      menuItem.radioGroup?.onSelect?.(value);
                    }}
                  >
                    {menuItem.radioGroup.radioItems.map((radioItem, index) => (
                      <DropdownMenuRadioItem
                        key={`radio-item-${index}`}
                        value={radioItem.value}
                      >
                        <div className="flex flex-col text-xs leading-none">
                          <span className="text-xs leading-4 text-white">
                            {radioItem.label}
                          </span>
                          {radioItem.showValue && (
                            <span className="text-xs leading-2 text-gray-500">
                              {radioItem.value}
                            </span>
                          )}
                        </div>
                      </DropdownMenuRadioItem>
                    ))}
                  </DropdownMenuRadioGroup>
                  <DropdownMenuSeparator />
                </React.Fragment>
              ) : (
                <DropdownMenuItem
                  key={`menu-item-${index}`}
                  onSelect={() => {
                    menuItem.onSelect && menuItem.onSelect();
                  }}
                  disabled={menuItem.disabled}
                  className={`${
                    menuItem.disablePointerEvents && 'pointer-events-none'
                  } m-0 text-sm border-b border-gray-500 rounded-none py-2 px-4`}
                >
                  <div className="flex flex-col gap-1 cursor-default">
                    {menuItem.labelTitle && (
                      <div className="text-sm text-gray-500">
                        {menuItem.labelTitle}
                      </div>
                    )}
                    <div className="flex gap-2 items-center">
                      <div className="font-medium text-sm">
                        {menuItem.label}
                      </div>
                      {menuItem.extras &&
                        menuItem.extras.map((extra, index) => (
                          <React.Fragment key={`menu-extra-${index}`}>
                            {extra}
                          </React.Fragment>
                        ))}
                    </div>
                  </div>
                </DropdownMenuItem>
              )}
            </React.Fragment>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};
