import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export const openUrl = (url: string, isNewTab = false) =>
  window.open(url, isNewTab ? '_blank' : '_self', 'noreferrer');

dayjs.extend(relativeTime);

/**
 * Converts a timestamp to a relative time string.
 * @param {string} timestampStr - The timestamp string to convert.
 * @returns {string} The relative time string.
 */
export function getRelativeTime(timestampStr: any) {
  const date = dayjs(timestampStr);
  if (!date.isValid()) {
    return 'Invalid date';
  }

  return date.fromNow();
}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

type StorageKey = string;

export class LocalStorageService {
  static get<T>(key: StorageKey, defaultValue?: T | null): T | null {
    try {
      const item = window.localStorage.getItem(key);
      return item ? (JSON.parse(item) as T) : defaultValue ?? null;
    } catch (error) {
      console.warn(`Error reading localStorage key "${key}":`, error);
      return defaultValue ?? null;
    }
  }

  static set<T>(key: StorageKey, value: T): boolean {
    try {
      window.localStorage.setItem(key, JSON.stringify(value));
      return true;
    } catch (error) {
      console.warn(`Error setting localStorage key "${key}":`, error);
      return false;
    }
  }

  static remove(key: StorageKey): boolean {
    try {
      window.localStorage.removeItem(key);
      return true;
    } catch (error) {
      console.warn(`Error removing localStorage key "${key}":`, error);
      return false;
    }
  }

  static clear(): boolean {
    try {
      window.localStorage.clear();
      return true;
    } catch (error) {
      console.warn('Error clearing localStorage:', error);
      return false;
    }
  }
}
