import Axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import {
  sendSignInLink,
  signInToFirebaseWithCustomToken,
  signInToFirebaseWithIdToken,
  signInWithGithub,
  signInWithGoogle,
  signInWithSSO,
} from './firebaseServices';
import { useNavigate } from 'react-router-dom';
import {
  Animate,
  AuthContext,
  Button,
  Card,
  Form,
  Loader,
  Message,
  Row,
  useLocation,
  ViewContext,
} from 'components/lib';
import { LocalStorageService } from 'utils';
import { gtagService } from 'app/analytics/gtag';

export function Signin() {
  // context
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  // state
  const [magicLinkSent, setMagicLinkSent] = useState(false);
  const [form, setForm] = useState({
    email: {
      type: 'email',
      required: true,
      placeholder: 'Enter your email',
    },
  });

  useEffect(() => {
    const redirectTo = getQueryParam('redirectTo');
    if (redirectTo) {
      localStorage.setItem('redirectTo', redirectTo);
    }
    const codiumCustomToken = Cookies.get('codium_token');
    const qodoCustomToken = Cookies.get('qodo_token');
    const customToken = qodoCustomToken || codiumCustomToken;
    if (customToken) {
      handleCookieLogin(customToken);
      return;
    }
    const idToken = getQueryParam('_t');
    if (idToken) {
      handleIdTokenLogin(idToken);
      return;
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    // was an error message passed from the server router?
    const qs = location.search;
    if (qs.includes('error')) {
      const msg = decodeURIComponent(qs.substring(qs.indexOf('?error=') + 7));
      viewContext.notification.show(msg, 'error');
    }
  }, [location.search, viewContext.notification]);

  const getQueryParam = (paramName) => {
    const params = new URLSearchParams(window.location.search);
    return params.get(paramName);
  };

  const redirectAfterLogin = () => {
    const redirectTo = localStorage.getItem('redirectTo') || '';
    localStorage.removeItem('redirectTo');
    const user = LocalStorageService.get('user');
    gtagService.trackEvent('login_success', { user_id: user?.email });
    navigate(redirectTo.startsWith('/') ? redirectTo : `/${redirectTo}`);
  };

  const handleCookieLogin = async (customToken) => {
    const domain = window.location.hostname.split('.').slice(-2).join('.');
    Cookies.remove('codium_token', { path: '', domain: `.${domain}` });
    Cookies.remove('qodo_token', { path: '', domain: `.${domain}` });
    await signInToFirebaseWithCustomToken(customToken);
    redirectAfterLogin();
  };

  const handleIdTokenLogin = async (idToken) => {
    await signInToFirebaseWithIdToken(idToken);
    redirectAfterLogin();
  };

  const handleEmailLogin = async () => {
    const email = form.email.value;

    try {
      const response = await Axios.get('v1/auth/sso_organization_id', {
        params: { email: email },
        timeout: 5000,
      });
      if (response.data) {
        signInWithSSO(email, response.data);
        return;
      }
    } catch (error) {
      // TODO: show something to the user?
      return;
    }

    await sendSignInLink(email);
    setMagicLinkSent(true);
  };

  if (loading) {
    return <Loader center />;
  }

  return (
    <Animate type="pop">
      <Row>
        {magicLinkSent ? (
          <div className="w-full md:w-11/12 lg:w-7/12 xl:w-5/12 mx-auto">
            <Message
              title="Check Your Email"
              type="success"
              text="Please click the link in your email inbox to sign in."
            />
          </div>
        ) : (
          <Card
            loading={loading}
            restrictWidth
            center
            styleClass="centered"
            className={'gap-4 rounded-lg w-full md:w-8/12 lg:w-6/12'}
          >
            <div className="text-gray-200 text-2xl md:text-3xl">Sign in</div>
            <div className="text-gray-400 font-normal leading-6 text-center text-sm md:text-base">
              No spam. No strings attached.
              <br />
              Unsubscribe any time.
            </div>
            <div className="flex flex-col">
              <Button
                iconPack="fontawesome"
                icon="fa-brands fa-google"
                iconButton
                text="Continue with Google"
                className="mb-3 bg-zinc-700 w-full"
                rounded
                action={signInWithGoogle}
              />
              <Button
                iconPack="fontawesome"
                icon="fa-brands fa-github"
                iconButton
                text="Continue with Github"
                className="mx-auto bg-zinc-700 w-full md:mx-0"
                rounded
                action={signInWithGithub}
              />
            </div>
            <div className="text-gray-600 font-medium text-xs md:text-base">
              OR
            </div>
            <Form
              className={'w-full'}
              inputs={form}
              callback={handleEmailLogin}
              buttonText="Continue with Email"
            />
          </Card>
        )}
      </Row>
    </Animate>
  );
}
