import Bitbucket from 'assets/Bitbucket.svg';
import Github from 'assets/Github.svg';
import Gitlab from 'assets/Gitlab.svg';
import JiraCloud from 'assets/JiraCloud.svg';
import JiraServer from 'assets/JiraServer.svg';
import { useNavigate } from 'react-router-dom';
import { openUrl } from 'utils';
import { events, mixpanelService } from '../../app/analytics';
import { jiraCloudQodoConnect, jiraServerQodoConnect } from '../../constants';
import { IconLink } from 'components/qodo/qodoIconLink';
import { gtagService } from 'app/analytics/gtag';

type GitProviderTypes = 'Github' | 'Gitlab' | 'Bitbucket';
type TicketTrackingSystemTypes = 'JiraCloud' | 'JiraServer';

const eventNames: Record<TicketTrackingSystemTypes, string> = {
  JiraCloud: events.JIRA_CLOUD_CONNECT_CLICK,
  JiraServer: events.JIRA_SERVER_CONNECT_CLICK,
};

type ProviderItem = {
  name: GitProviderTypes;
  imgUrl: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  onClickHandler: () => void;
  tooltipContent: string;
};

export const QodoMergeButtons = () => {
  const navigate = useNavigate();

  const onQodoMergeClick = (provider: GitProviderTypes) => {
    gtagService.trackEvent('install_qodo_click', {
      app_environment: provider,
      app_product: 'Qodo Merge',
    });
    navigate('/qodo-merge');
  };

  const providers: ProviderItem[] = [
    {
      name: 'Github',
      imgUrl: Github,
      onClickHandler: () => onQodoMergeClick('Github'),
      tooltipContent: 'Github',
    },
    {
      name: 'Gitlab',
      imgUrl: Gitlab,
      onClickHandler: () => onQodoMergeClick('Gitlab'),
      tooltipContent: 'Gitlab',
    },
    {
      name: 'Bitbucket',
      imgUrl: Bitbucket,
      onClickHandler: () => onQodoMergeClick('Bitbucket'),
      tooltipContent: 'Bitbucket',
    },
  ];

  return (
    <div className="flex gap-2 flex-wrap">
      {providers.map((item) => (
        <IconLink key={item.name} {...item} />
      ))}
    </div>
  );
};

type TicketTrackingLinkItem = {
  name: TicketTrackingSystemTypes;
  imgUrl: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  url: string;
  tooltipContent: string;
  disabled?: boolean;
  onClickHandler: (url: string) => void;
};

type CreateLinksProps = {
  onTicketTrackingSystemClick: (
    url: string,
    name: TicketTrackingSystemTypes,
  ) => void;
};

const createTicketTrackingLinks = ({
  onTicketTrackingSystemClick,
}: CreateLinksProps): TicketTrackingLinkItem[] => {
  return [
    {
      name: 'JiraCloud',
      imgUrl: JiraCloud,
      url: jiraCloudQodoConnect,
      tooltipContent: 'Jira Cloud',
      onClickHandler: (url: string) =>
        onTicketTrackingSystemClick(url, 'JiraCloud'),
    },
    {
      name: 'JiraServer',
      imgUrl: JiraServer,
      url: jiraServerQodoConnect,
      tooltipContent: 'Jira Server',
      onClickHandler: (url: string) =>
        onTicketTrackingSystemClick(url, 'JiraServer'),
    },
  ];
};

export const TicketTrackingSystemsButtons = () => {
  const onTicketTrackingSystemClick = (
    url: string,
    name: TicketTrackingSystemTypes,
  ) => {
    gtagService.trackEvent('install_qodo_click', {
      app_environment: name,
      app_product: 'Qodo Merge',
    });
    mixpanelService.trackEvent(
      eventNames[name] ?? 'Ticket tracking system click',
      {
        ticketTrackingSystemType: name,
      },
    );
    openUrl(url, true);
  };

  const links = createTicketTrackingLinks({
    onTicketTrackingSystemClick,
  });

  return (
    <div className="flex flex-row gap-2">
      {links.map((item) => (
        <IconLink key={item.name} {...item} />
      ))}
    </div>
  );
};
