const ButtonStyle = {
  base: 'rounded ease-in-out duration-500 inline-flex items-center justify-center select-none outline-none disabled:opacity-50 disabled:cursor-default',
  xs: 'gap-0.5 px-2 py-1.5',
  s: 'gap-1 px-3 py-1.5',
  l: 'gap-1 px-3 py-3 ',
  primary: 'text-purple-900 enabled:hover:bg-purple-200 bg-purple-300',
  secondary:
    'text-purple-300 enabled:hover:bg-purple-mutted-500 bg-purple-mutted-600',
  ghost:
    'text-purple-300 border-purple-mutted-600 border  enabled:hover:border-purple-mutted-500 enabled:hover:bg-purple-mutted-500',
};

export default ButtonStyle;
