import { ClassHelper, Icon } from 'components/lib';
import React from 'react';
import ButtonStyle from './button.tailwind';
import IconStyle from './icon.tailwind';
import TextStyle from './text.tailwind';

/**
 * QodoButtonLabel- A generic Text Button with predefined properties
 *
 * @param {Object} props
 * @param {string|React.ReactNode} [props.buttonText='Label'] - Button's text
 * @param {()=>void} [props.onClick=()=>{}] - Button's onClick callback
 * @param {"xs"|"s"|"l"} [props.size='l'] - Button's size
 * @param {"primary"|"secondary"|"ghost"|"none"} [props.type='none'] - Button's type
 * @param {boolean} [props.disabled=false] - Button's disabled state
 * @param {boolean} [props.leftChevron=false] - Button's left chevron
 * @param {boolean} [props.rightChevron=false] - Button's right icon
 * @param {string} [props.leftCustomIcon=''] - Button's left custom icon URL
 * @param {string} [props.rightCustomIcon=''] - Button's right custom icon URL
 * @param {string} [props.classNames=''] - Button style overrides
 */

export const QodoButtonLabel = ({
  buttonText = 'Select',
  onClick = () => {},
  size = 'l',
  type = 'none',
  leftChevron = false,
  disabled = false,
  rightChevron = false,
  leftCustomIcon = '',
  rightCustomIcon = '',
  classNames = '',
}) => {
  const buttonStyle = ClassHelper(ButtonStyle, { [size]: size, [type]: type });
  const iconStyle = ClassHelper(IconStyle, { [size]: size });
  const textStyle = ClassHelper(TextStyle, { [size]: size, [type]: type });
  const buttonContent = [];

  if (leftChevron) {
    buttonContent.push(<Icon image="chevron-down" className={iconStyle} />);
  }

  if (leftCustomIcon) {
    buttonContent.push(<img alt="left-icon" src={leftCustomIcon} />);
  }

  buttonContent.push(<span className={textStyle}>{buttonText}</span>);

  if (rightCustomIcon) {
    buttonContent.push(<img alt="right-icon" src={rightCustomIcon} />);
  }

  if (rightChevron) {
    buttonContent.push(<Icon image="chevron-down" className={iconStyle} />);
  }

  return (
    <button
      disabled={disabled}
      className={`${buttonStyle} ${classNames}`}
      onClick={onClick}
    >
      {buttonContent.map((contentElement, index) => (
        <React.Fragment key={`content-${index}`}>
          {contentElement}
        </React.Fragment>
      ))}
    </button>
  );
};
